<template>
  <el-dialog
    title="修改任务奖励"
    :visible.sync="$_visible"
    @close="onDialogClose"
    :show-close="false"
    width="500px">
    <el-form
      label-position="left"
      ref="form"
      :model="formInfo"
      :rules="rules"
      label-width="110px">
      <el-form-item
        label="完成程度"
        prop="completeness">
        <el-select
          size="small"
          style="width: 320px;"
          v-model="formInfo.completeness"
          placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="理由说明"
        prop="completionReason">
        <el-input
          type="textarea"
          style="width: 320px;"
          v-model="formInfo.completionReason"
          :autosize="{minRows: 4}">
        </el-input>
      </el-form-item>
    </el-form>
    <span
      slot="footer">
      <el-button
        size="small"
        @click="closeDialog">
        取消
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="updateAwards">
        确认修改
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    // 父组件传来的控制
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formInfo: {
        completeness: '',
        completionReason: ''
      },
      // 表单验证规则
      rules: {
        completeness: [
          { required: true, message: '请选择完成程度', trigger: 'blur' }
        ],
        completionReason: [
          { required: true, message: '请输入理由', trigger: 'blur' }
        ]
      },
      options: [{
        value: 100,
        label: '100%（全额发放奖励）'
      }, {
        value: 80,
        label: '80%（发放80%奖励）'
      }, {
        value: 50,
        label: '50%（发放50%奖励）'
      }, {
        value: 20,
        label: '20%（全额发放奖励）'
      }, {
        value: 0,
        label: '0（不发放奖励）'
      }]
    }
  },
  computed: {
    $_visible: {
      get () {
        return this.visible
      },
      set (newValue) {
        this.$emit('update:visible', newValue)
      }
    },
    taskId: {
      get () {
        return this.$route.params.taskId
      }
    }
  },
  methods: {
    // 修改任务奖励
    updateAwards () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          api.adminUpdateReward(this.taskId, { ...this.formInfo }).then(res => {
            if (res.data.code === 0) {
              this.$message.success('操作成功')
              this.closeDialog()
              this.$router.push('/admin/complains')
            } else {
              this.$message.error('操作失败')
            }
          }).catch((err) => {
            console.log(err)
            this.$message.error('请求异常')
          })
        } else {
          return false
        }
      })
    },
    // 关闭dialog
    closeDialog () {
      this.$_visible = false
    },
    // input框重置
    onDialogClose () {
      // 将表单重置
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  padding: 0 20px 20px 20px;
  .el-dialog__header {
    height: 60px;
    padding: 0;
    line-height: 60px;
    font-size: 16px;
    font-weight: bold;
    color: #303133;
  }
  .el-dialog__body {
    padding: 0 20px 0 20px;
    margin-bottom: 40px;
  }
  .el-dialog__footer {
    padding: 0;
  }
}
</style>
