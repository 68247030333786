<template>
  <div class="main_container">
    <div class="task_detail_container">

      <!-- 详情页头部 -->
      <detail-title
        :backToPath="'/admin/complains'"
        :descriptionList="descriptionList"
      ></detail-title>

      <!-- 详情信息列表 -->
      <task-description
        :descriptionList="descriptionList"
        :isMaterials="true">
      </task-description>

      <!-- 修改任务奖励按钮 -->
      <div class="task_submit">
        <el-button
          size="small"
          type="primary"
          @click="updateAwardsVisible = true">
          修改任务奖励
        </el-button>
      </div>

    </div>

    <!-- 任务相关信息 -->
    <task-related-info></task-related-info>

    <!-- 修改任务奖励 -->
    <update-task-awards
      :visible.sync="updateAwardsVisible">
    </update-task-awards>
  </div>
</template>

<script>
import api from '@/api/index'
import TaskRelatedInfo from '@/components/task-detail/related-info/index.vue'
import DetailTitle from '@/components/task-detail/title/index.vue'
import UpdateTaskAwards from '../components/update-task-awards.vue'
import TaskDescription from '@/components/task-detail/description/index.vue'

export default {
  components: {
    TaskRelatedInfo,
    DetailTitle,
    UpdateTaskAwards,
    TaskDescription
  },
  data () {
    return {
      updateAwardsVisible: false,
      descriptionList: {}
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  created () {
    this.getTaskDetail()
  },
  methods: {
    handleUpdateAwards () {
      this.updateAwardsVisible = false
    },
    // 获取任务详情
    getTaskDetail () {
      this.loading = true
      api.taskDetail(this.taskId).then(res => {
        if (res.data.code === 0) {
          this.descriptionList = res.data.data ? res.data.data : {}
        } else {
          this.$message.error('获取信息失败')
        }
      }).catch(() => {
        this.$message.error('请求异常')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main_container {
  width: 100%;
  height: 100%;
  display: flex;
}
.task_detail_container {
  min-width: 1200px;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  overflow: auto;
  .task_submit {
    height: 80px;
    padding: 0 30px;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
}
</style>
